(function() {
    "use strict";

    angular.module("app")
        .directive("cAside", function ($rootScope, _v, _view) {
            return {
                restrict: "E",
                templateUrl: "t-c-aside",
                replace: true,
                scope: true,
                controllerAs: 'vm',
                controller: function() {
                    var vm = this;

                    vm.weatherInfo = {
                        isOpened: false,
                        open: function () {
                            vm.weatherInfo.isOpened = true;
                            $rootScope.$broadcast("aside:opened");
                        },
                        close: function () {
                            vm.weatherInfo.isOpened = false;
                            $rootScope.$broadcast("aside:closed");
                        }
                    };

                    vm.forecastInfo = {
                        open: function () {
                            _view.setForecastOpened(true);
                        }
                    };

                    vm.addTask = {
                        open: function() {
                            if (_view.is.map()) {
                                _v.change({set: {addCropMonitor: true}});
                            } else {
                                _v.change({set: {addCropMonitor: true, view: 'm'}});
                            }
                        }
                    };

                    vm.addSpray = {
                        open: function() {
                            if (_view.is.map()) {
                                _v.change({set: {editSpray: -1}});
                            } else {
                                _v.change({set: {editSpray: -1, view: 'm'}});
                            }
                        }
                    };

                    vm.editMap = function () {
                        console.log("editMap");
                        $rootScope.$broadcast("aside:editMap");
                    };
                }
            };
        });
}());